import React, { useEffect, useRef, useState } from "react";
import "../css/layout.css";
import css from "../Modulecss/Home.module.css";
import axios from "axios";
import { NavLink, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { io } from "socket.io-client"
import { decrypt } from "../utils/cryptoUtils";


import BetCard from "./BetCard";
import RunningCard from "./RunningCard";




export default function Homepage({ walletUpdate }) {

  const connectSocket = () => {
    const newSocket = io(process.env.REACT_APP_LOCAL_API);
    setSocket(newSocket);
    return newSocket;
  };

  // eslint-disable-next-line
  const disconnectSocket = () => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  };
  //const history = useHistory();
  let userID = useRef();
  // eslint-disable-next-line
  const isMounted = useRef(true);
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  /// user details start

  const [user, setUser] = useState();
  const [created, setCreated] = useState([]);
  const [socket, setSocket] = useState();
  // eslint-disable-next-line
  const [userAllData, setUserAllData] = useState()

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };
    try {
      await axios.get(`${baseUrl}me`, { headers })
        .then(
          (res) => {
            const decryptData = decrypt(res.data.data);
            setUser(decryptData._id);
            setUserAllData(decryptData)
            userID.current = decryptData._id;
            setMount(true);
          }
        )
        .catch((error) => {
          console.log(error)
        })
    }
    catch (e) {
      console.log(e)
    }
  };

  /// user details end
  // eslint-disable-next-line
  const [game_type, setGame_type] = useState(
    useLocation().pathname.split("/")[2]
  );

  const [Game_Ammount, setGame_Ammount] = useState();

  //   console.log(game_type);

  const ChallengeCreate = (e) => {
  if (Game_Ammount % 50 !== 0) {
    Swal.fire({
      title: "Please enter an amount in multiples of 50",
      icon: "warning",
      confirmButtonText: "OK",
    });
    return;
  }

  const access_token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${access_token}`,
  };

  axios.post(
    baseUrl + `challange/create`, { Game_Ammount, Game_type: game_type }, { headers }
  )
    .then((res) => {
      if (res.data.msg === 'you can not create same amount challenge.') {
        Swal.fire({
          title: 'You can not create the same amount challenge.',
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
      else if (res.data.msg === "you have already enrolled") {
        Swal.fire({
          title: "You have already enrolled",
          icon: "warning",
          confirmButtonText: "OK",
        });
      } else if (res.data.msg === "You can set maximum 2 battles.") {
        Swal.fire({
          title: "You can set a maximum of 2 battles.",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
      else if (res.data.msg === "Insufficient balance") {
        Swal.fire({
          title: "Insufficient balance",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
      else if (
        res.data.msg ===
        "Game amount should be Greater than or equal to 50 and less than or equal to 30000"
      ) {
        Swal.fire({
          title: "Game amount should be between 50 and 30000",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
      else {
        socket.emit("message", JSON.stringify({ event: "gameCreated", data: "" }));
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

  const [allgame, setallgame] = useState([]);
  const [mount, setMount] = useState(false);
  //const [ALL, setALL] = useState();
  const [runningGames, setRunningGames] = useState();
  const [ownRunning, setOwnRunning] = useState([]);
  const Allgames = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(baseUrl + `challange/all`, { headers })
      .then((res) => {
        const decryptData = decrypt(res.data)
        let owenedCreated = [], remainingGame = [];
        decryptData.forEach(function (ele) {
          if ((ele.Created_by._id === user) && (ele.Status === "new" || ele.Status === "requested")) {
            owenedCreated.push(ele);
          }
          else {
            remainingGame.push(ele);
          }
        })
        setCreated(owenedCreated);
        setallgame(remainingGame);
      })
      .catch(e => {
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.reload()
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: 'Please refresh!',
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      })
  };

  const runningGame = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(baseUrl + `challange/running/all`, { headers })
      .then((res) => {
        const decryptData = decrypt(res.data)
        let owenedRunning = [], remainingRunning = [];
        decryptData.forEach(function (ele) {
          if (ele.Created_by && ele.Accepetd_By)
            if ((ele.Created_by._id === userID.current) || (ele.Accepetd_By._id === userID.current)) {
              owenedRunning.push(ele);
            }
            else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
      })
      .catch((e) => {
        console.log('errror', e)
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.reload()
          //    setTimeout(() => {
          // //  history.push("/login")
          // }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: 'Please refresh!',
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      })
  };



  //   game commission Rules
  function winnAmount(gameAmount) {
    let profit = null;
    if (gameAmount >= 50 && gameAmount <= 250)
      profit = gameAmount * 10 / 100;
    else if (gameAmount > 250 && gameAmount <= 500)
      profit = 25;
    else if (gameAmount > 500)
      profit = gameAmount * 5 / 100;
    return gameAmount - profit;
  }



  useEffect(() => {
    const socketInstance = connectSocket();
    // Socket Connecting ... 
    socketInstance.on("connect", () => {
      // console.log("connection Id: ", socketInstance.id)
    })

    // socket get call by user even t
    // 1. game create any user then call this socket
    socketInstance.on('recieveGame', (data) => {
      const { owenedCreated, remainingGame } = data.reduce(
        (acc, ele) => {
          if (ele.Created_by && (ele.Created_by._id === userID.current) && (ele.Status === "new" || ele.Status === "requested")) {
            acc.owenedCreated.push(ele); // Add to owned created games
          } else {
            acc.remainingGame.push(ele); // Add to remaining games
          }
          return acc;
        },
        { owenedCreated: [], remainingGame: [] }
      );
      setCreated(owenedCreated);
      setallgame(remainingGame);
    });



    // 2. update running games
    socketInstance.on('updateRunning', (data) => {
      let owenedCreated = [], remainingGame = [];
      data.forEach(function (ele) {
        if (ele.Created_by && (ele.Created_by._id === userID.current) && (ele.Status === "new" || ele.Status === "requested")) {
          owenedCreated.push(ele); // Add to owned created games
        } else {
          remainingGame.push(ele); // Add to remaining games
        }
      });
      setCreated(owenedCreated);
      setallgame(remainingGame);
      // walletUpdate();
      role();
    })



    socketInstance.on("acceptor_seen", (data) => {
      let owenedCreated = [], remainingGame = [];
      data.openBattle.forEach(function (ele) {
        if (ele.Created_by && (ele.Created_by._id === userID.current) && (ele.Status === "new" || ele.Status === "requested")) {
          owenedCreated.push(ele); // Add to owned created games
        } else {
          remainingGame.push(ele); // Add to remaining games
        }
      });
      setCreated(owenedCreated);
      setallgame(remainingGame);
      let owenedRunning = [], remainingRunning = [];
      data.runningBattle.forEach(function (ele) {
        if (ele.Created_by && ele.Accepetd_By && (ele.Created_by._id === userID.current || ele.Accepetd_By._id === userID.current)) {
          owenedRunning.push(ele); // Add to owned running games
        } else {
          remainingRunning.push(ele); // Add to remaining running games
        }
      });
      setOwnRunning(owenedRunning);
      setRunningGames(remainingRunning);
      // walletUpdate(); // Update wallet
    });



    // 2. accepter user accept any challange then calling this
    socketInstance.on("challengeAccepted", (data) => {
      let owenedCreated = [], remainingGame = [];
      data.forEach(function (ele) {
        if (ele.Created_by && (ele.Created_by._id === userID) && (ele.Status === "new" || ele.Status === "requested")) {
          owenedCreated.push(ele); // Add to owned created games
        } else {
          remainingGame.push(ele); // Add to remaining games
        }
      });
      setCreated(owenedCreated);
      setallgame(remainingGame);
      walletUpdate()
    });


    socketInstance.on("updateReject", (data) => {
      let owenedCreated = [], remainingGame = [];
      data.forEach(function (ele) {
        if (ele.Created_by)
          if ((ele.Created_by._id === userID.current) && (ele.Status === "new" || ele.Status === "requested")) {
            owenedCreated.push(ele);
          }
          else {
            remainingGame.push(ele);
          }
      })
      setCreated(owenedCreated);
      setallgame(remainingGame);
      walletUpdate()
      role();
    });


    // 3. creator start challenge then calling this socket
    socketInstance.on("startAcepptor", (data) => {
      let owenedCreated = [], remainingGame = [];
      data.forEach(function (ele) {
        if (ele.Created_by && (ele.Created_by._id === userID.current) && (ele.Status === "new" || ele.Status === "requested")) {
          owenedCreated.push(ele); // Add to owned created games
        } else {
          remainingGame.push(ele); // Add to remaining games
        }
      });
      setCreated(owenedCreated);
      setallgame(remainingGame);
      walletUpdate();
      role();
    });





    //  all running challenge creating and join by other players
    socketInstance.on("ongoingChallenge", (data) => {
      let owenedCreated = [], remainingGame = [];
      data.openBattle.forEach(function (ele) {
        if (ele.Created_by && (ele.Created_by._id === userID.current) && (ele.Status === "new" || ele.Status === "requested")) {
          owenedCreated.push(ele); // Add to owned created games
        } else {
          remainingGame.push(ele); // Add to remaining games
        }
      });
      setCreated(owenedCreated);
      setallgame(remainingGame);
      let owenedRunning = [], remainingRunning = [];
      data.runningBattle.forEach(function (ele) {
        if (ele.Created_by && ele.Accepetd_By && (ele.Created_by._id === userID.current || ele.Accepetd_By._id === userID.current)) {
          owenedRunning.push(ele); // Add to owned running games
        } else {
          remainingRunning.push(ele); // Add to remaining running games
        }
      });
      setOwnRunning(owenedRunning);
      setRunningGames(remainingRunning);
    });



    socketInstance.on("updateDelete", (data) => {
      let owenedCreated = [], remainingGame = [];
      data.forEach(function (ele) {
        if (ele.Created_by && (ele.Created_by._id === userID.current) && (ele.Status === "new" || ele.Status === "requested")) {
          owenedCreated.push(ele); // Add to owned created games
        } else {
          remainingGame.push(ele); // Add to remaining games
        }
      });
      setallgame(remainingGame);
      setCreated(owenedCreated);
    })

    socketInstance.on("resultUpdateReq", (data) => {
      let owenedRunning = [], remainingRunning = [];
      data.forEach(function (ele) {
        if (ele.Created_by && ele.Accepetd_By)
          if ((ele.Created_by._id === userID.current) || (ele.Accepetd_By._id === userID.current)) {
            owenedRunning.push(ele);
          }
          else {
            remainingRunning.push(ele);
          }
      });
      // console.log('>>>>>>>>', owenedRunning, remainingRunning)
      setOwnRunning(owenedRunning);
      setRunningGames(remainingRunning);
      walletUpdate();
      role();
    });

    return () => {
      socketInstance.disconnect();
      console.log('Disconnected from server due to page change');
    };
    // eslint-disable-next-line
  }, [])


  // eslint-disable-next-line
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    try {
      const response = await fetch(baseUrl + "settings/data");

      // Check if the response is empty (status 204: No Content)
      if (response.status === 204 || response.headers.get("content-length") === "0") {
        throw new Error("No content in response");
      }

      // Check if the response is OK (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Try parsing the JSON
      const data = await response.json();

      // Now decrypt the data
      const decryptData = decrypt(data.data); // Assuming the encrypted data is inside `data.data`
      // console.log(decryptData.data);
      setWebsiteSettings(decryptData.data);

      // You can further handle the decrypted data here
      // setWebsiteSettings(decryptData);
    } catch (error) {
      console.log("Error fetching data:", error);
      document.title = (WebSitesettings) ? WebSitesettings.WebTitle : '';
    }
  };


  useEffect(() => {
    fetchData()
    let access_token = localStorage.getItem('token');
    access_token = localStorage.getItem('token');
    if (!access_token) {
      window.location.reload()
      setTimeout(() => {
        //  history.push("/login")
      }, 500);;
    }
    role();
    if (mount) {
      Allgames();
      runningGame();
    }
    // eslint-disable-next-line
  }, [mount]);
  //accept Challange

  const AcceptChallang = (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .put(
        baseUrl + `challange/accept/${id}`,
        {
          Accepetd_By: headers,
          Acceptor_by_Creator_at: Date.now(),
        },
        {
          headers,
        }
      )
      .then((res) => {
        if (res.data.msg === "you have already enrolled") {
          Swal.fire({
            title: "You have already enrolled",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        if (res.data.msg === "Insufficient balance") {
          Swal.fire({
            title: "Insufficient balance",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          Allgames(res.data);
          socket.emit("message", JSON.stringify({ event: "acceptGame", data: "" }));
        }
      })
      .catch((e) => {
        console.log(e)
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.reload()
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: 'Please refresh!',
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  //reject Game
  const RejectGame = (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios.put(baseUrl + `challange/reject/${id}`, { Accepetd_By: null, Status: "new", Acceptor_by_Creator_at: null }, { headers })
      .then(
        (res) => {
          socket.emit("message", JSON.stringify({ event: "gameRejected", data: "id" }));
        }
      ).catch((e) => {
        console.log(e);
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.reload()
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: 'Please refresh!',
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  //delete
  const deleteChallenge = (_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios.delete(baseUrl + `challange/delete/${_id}`, { headers })
      .then((res) => {
        socket.emit("message", JSON.stringify({ event: "deleteGame", data: _id }))
      })
      .catch((e) => {
        //console.log(e);
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.reload()
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: 'Please refresh!',
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  ///challange/running/update/

  const updateChallenge = (_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .put(
        baseUrl + `challange/running/update/${_id}`,
        {
          Acceptor_seen: true,
        },
        { headers }
      )
      .then((res) => {
        socket.emit("message", JSON.stringify({ event: "game_seen", data: "" }));
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.reload()
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: 'Please refresh!',
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        console.log(e)
      });
  };

  // const [roomCode, setRoomCode] = useState()

  const getPost = async (Id) => {
    if (game_type === 'Ludo Classics Manual' || game_type === 'Ludo 1 Goti' || game_type === 'Ludo Ulta') {
      // socket.emit('roomCodeManual', { game_id: Id, status: 'running' })
      socket.emit("message", JSON.stringify({ event: "roomCodeManual", data: { game_id: Id, status: 'running' } }));

    }
    else if (game_type === 'Ludo Classics Auto') {
      socket.emit("message", JSON.stringify({ event: "roomCodeAuto", data: { game_id: Id, status: 'running' } }));

    }
    else if (game_type === 'Ludo Popular') {
      socket.emit("message", JSON.stringify({ event: "popularroomCode", data: { game_id: Id, status: 'running' } }));


    }
  }



  return (
    <>

      <div className="leftContainer" style={{ minHeight: '100vh' }}>
        <div className={css.mainArea} style={{ paddingTop: "60px", minHeight: '100vh' }}>

          {/* {WebSitesettings && WebSitesettings.MessageHome.length > 1 &&
            <span className={`${css.cxy} ${css.battleInputHeader} text-danger mt-3 px-3 text-center`}>
              {WebSitesettings.MessageHome}
            </span>
          }   */}



          <span className={`${css.cxy} ${css.battleInputHeader} mt-3 text-success`}>
            Create a Battle!
          </span>

          <div className="mx-auto d-flex my-2 w-50">
            <div>
              <input className={css.formControl} type="tel" placeholder="Amount" onChange={(e) => setGame_Ammount(e.target.value)} />
            </div>

            <div className="set ml-1 ">
              {" "}
              <button
                className={`bg-green ${css.playButton} cxy m-1 position-static `}
                style={{ margin: "20px !important" }}
                onClick={(e) => { e.preventDefault(); ChallengeCreate(); }}
              >
                Set
              </button>

            </div>
          </div>

          <div className={css.dividerX}></div>

          <div className="px-4 py-3">
            <div className="mb-3">
              <img src={process.env.PUBLIC_URL + "/Images/Homepage/battleIcon.png"} alt="battelicon" width="20px" />
              <span className={`ml-2 ${css.gamesSectionTitle}`}> Open Battles </span>
              <span
                className={`${css.gamesSectionHeadline} text-uppercase position-absolute mt-2 font-weight-bold`}
                style={{ right: "1.5rem" }}
              >
                Rules
                <NavLink to="/Rules">
                  <img className="ml-2" src={process.env.PUBLIC_URL + "/Images/Homepage/info.png"} alt="info" />
                </NavLink>
              </span>
            </div>


            {created &&
              created.map((allgame) =>
                (allgame.Game_type === game_type) &&
                (<BetCard key={allgame._id} allgame={allgame} user={user} deleteChallenge={deleteChallenge} getPost={getPost} RejectGame={RejectGame} winnAmount={winnAmount} AcceptChallang={AcceptChallang} updateChallenge={updateChallenge} />)
              )
            }
            {allgame &&
              allgame.map((allgame) =>
                ((allgame.Status === "new" ||
                  (allgame.Status === "requested" && (user === allgame.Created_by._id || user === allgame.Accepetd_By._id)) ||
                  (allgame.Status === "running" && user === allgame.Accepetd_By._id && allgame.Acceptor_seen === false))
                  && allgame.Game_type === game_type)
                &&
                (
                  <BetCard key={allgame._id} allgame={allgame} user={user} deleteChallenge={deleteChallenge} getPost={getPost} RejectGame={RejectGame} winnAmount={winnAmount} AcceptChallang={AcceptChallang} updateChallenge={updateChallenge} />
                ))
            }
          </div>


          <div className={css.dividerX}></div>
          <div className="px-4 py-3">
            <div className="mb-2">
              <img src={process.env.PUBLIC_URL + "/Images/Homepage/battleIcon.png"} alt="battelicon" width="20px" />
              <span className={`ml-2 ${css.gamesSectionTitle}`}> Running Battles </span>
            </div>

            {ownRunning && ownRunning.map((runnig) => {
              if (((user === runnig.Accepetd_By._id ?
                ((runnig.Status === "running" && user === runnig.Accepetd_By._id && runnig.Acceptor_seen === true) ||
                  (runnig.Status === "pending")) :
                ((runnig.Status === "running" && user === runnig.Created_by._id) ||
                  (runnig.Status === "pending"))) ||
                runnig.Status === "conflict") && runnig.Game_type === game_type)
                return (
                  <RunningCard key={runnig._id} runnig={runnig} user={user} winnAmount={winnAmount} />
                );
              // Return null if the condition is not met
              return null;
            })}

            {runningGames && runningGames.map((runnig) => {
              if (((user === runnig.Accepetd_By._id || user === runnig.Created_by._id) ?
                (user === runnig.Accepetd_By._id ?
                  ((runnig.Status === "running" && user === runnig.Accepetd_By._id && runnig.Acceptor_seen === true) ||
                    (runnig.Status === "pending" && runnig.Acceptor_status === null)) :
                  ((runnig.Status === "running" && user === runnig.Created_by._id) ||
                    (runnig.Status === "pending" && runnig.Creator_Status === null))) :
                (runnig.Status === "running" || runnig.Status === "pending")) &&
                runnig.Game_type === game_type)
                return (
                  <RunningCard key={runnig._id} runnig={runnig} user={user} winnAmount={winnAmount} />
                );

              // Return null if the condition is not met
              return null;
            })}

          </div>
        </div>
      </div>
      {/* <div className="rightContainer">
        <Rightcontainer />
      </div> */}
    </>
  );
}