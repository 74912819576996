import React, { useEffect, useState } from 'react';
import css from "../css/Refer.module.css";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { decrypt } from '../utils/cryptoUtils';

const Refer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [user, setUser] = useState();
  const [cardData, setGame] = useState([]);
  const [WebSitesettings, setWebsiteSettings] = useState("");

  const Cashheader = async () => {
    try {
      let access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      const res = await axios.get(baseUrl + `me`, { headers });
      const decryptData = decrypt(res.data.data);
      setUser(decryptData);
      Allgames(decryptData.referral_code);
    } catch (e) {
      console.error(e.message);
    }
  };

  const Allgames = async (id) => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      const res = await axios.get(baseUrl + `referral/code/${id}`, { headers });
      setGame(res.data);
    } catch (e) {
      console.error(e.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(baseUrl + "settings/data");
      if (response.status === 204 || response.headers.get("content-length") === "0") {
        throw new Error("No content in response");
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const decryptData = decrypt(data.data);
      setWebsiteSettings(decryptData.data);
    } catch (error) {
      console.log("Error fetching data:", error);
      document.title = WebSitesettings ? WebSitesettings.WebTitle : '';
    }
  };

  useEffect(() => {
    Cashheader();
    fetchData();
  }, []);

  const copyCode = () => {
    navigator.clipboard.writeText(user.referral_code);
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Referral Code Copied ✨',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  if (!user) {
    return null;
  }

  return (
    <div>
      <div className='leftContainer' style={{ height: '100vh' }}>
        <div className={`${css.center_xy} pt-5`}>
          <picture className="mt-1">
  <img alt="img" width="130px" src="https://i.postimg.cc/t4MxvjQw/refer-and-earn.gif" className="snip-img" />
  <img alt="img" width="130px" src="https://i.postimg.cc/kGxrQbtd/Media-240219-061445.gif" className="snip-img" />
</picture>
          <div className="mb-1">
            <div className="font-15">
              <span className={css.earnText}>✅ Earn Now - Unlimited Joy! 🎉</span>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <span className={css.referNowText}>⭐ Refer Your Friends & Earn Exciting Rewards! 🎁</span>
            </div>
            <div className="mt-4 text-center font-9">
              <span>Current Earning: </span>
              <b>₹{user.referral_wallet.toFixed(2)}</b>
              <Link className="ml-2 btn btn-outline-success" to="/Redeem">
                ⭐ Redeem
              </Link>
            </div>
            <div className="text-center font-9 mt-2">
              <span>Total Earned: </span>
              <b>₹{user.referral_earning.toFixed(2)}</b>
            </div>
            <div className={`${css.progress} mt-3`}>
              <div
                className={`${css.progress_bar} ${css.progress_bar_striped} ${css.bg_success}`}
                aria-valuenow={user.referral_earning}
                aria-valuemax={10000}
                style={{ width: `${(user.referral_earning * 100) / 10000}%` }}
              ></div>
            </div>
            <div className="font-9">
              <span>Max: ₹10,000</span>
              <Link className="float-right btn btn-link" to="/update-pan">
                💡 Upgrade Limit
              </Link>
            </div>
            <div className={`${css.text_bold} mt-4 text-center`}> 💸
              <span>Your Refer Code: </span>
              <span className={css.referralCode}>{user.referral_code}</span>
              <i
                className="ri-clipboard-fill ml-2 "
                style={{ fontSize: '20px', color: '#007bff' }}
                onClick={copyCode}
              ></i>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <span>⭐ Total Referrals:&nbsp;</span>
              <b>{cardData}</b>
            </div>
          </div>
        </div>

        <div className="mx-3 my-4">
          <div className={`${css.font_11} ${css.text_bold}`}>✨ Refer & Earn Rules 🎁</div>
          <div className="d-flex align-items-center m-3">
            <picture>
              <img alt="img" width="82px" src={process.env.PUBLIC_URL + '/Images/refer/giftbanner.png'} className="snip-img" />
            </picture>
            <div className={`${css.font_9} mx-3`} style={{ width: '63%' }}>
              <div>When your friend signs up on Our website or App from your referral link,</div>
              <div className={`${css.font_8} ${css.c_green} mt-2`}>
                You get &nbsp;
                <strong>2% Commission</strong> on your &nbsp;
                <strong>referral's winnings.</strong>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center m-3">
            <picture>
              <img alt="img" width="82px" src={process.env.PUBLIC_URL + '/Images/refer/banner.png'} className="snip-img" />
            </picture>
            <div className={`${css.font_9} mx-3`} style={{ width: '63%' }}>
              <div>Suppose your referral plays a battle for ₹10,000 Cash,</div>
              <div className={`${css.font_8} ${css.c_green} mt-2`}>
                You get &nbsp;
                <strong>₹200 Cash</strong>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-3 my-4">
          <a
            href={`https://wa.me?text=Play Ludo and earn ₹10000 daily. Commission Charge - 5% Only Referral - 2% On All Games 24x7 Live Chat Support Instant Withdrawal Via UPI/BANK Register Now: https://ludokheloyaar.com/login/${user.referral_code} Register Now, My refer code is ${user.referral_code}.`}
            style={{ width: '100%' }}
          >
            <button className="bg-success refer-button cxy w-100">
              <img alt="img" width="20px" src={process.env.PUBLIC_URL + 'whatsapp.png'} className="snip-img" /> &nbsp;
              💙 Share in Whatsapp
            </button>
          </a>
        </div>

        <div className="mx-3 my-4">
          <a
            href={`https://telegram.me/share/url?url=Play Ludo and earn ₹10000 daily. Commission Charge - 5% Only Referral - 2% On All Games 24x7 Live Chat Support Instant Withdrawal Via UPI/BANK Register Now: https://ludokheloyaar.com/login/${user.referral_code} Register Now, My refer code is ${user.referral_code}.`}
            style={{ width: '100%' }}
          >
            <button className="bg-primary refer-button cxy w-100">
              <img alt="img" width="20px" src={process.env.PUBLIC_URL + 'telegram.svg'} className="snip-img" />&nbsp;
              💌 Share in Telegram
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Refer;