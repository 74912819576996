import React from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Rightcontainer from "../Components/Rightcontainer";

const calculateCommission = (amount) => {
  if (amount < 500) {
    return 25;
  } else {
    return (5 / 100) * amount;
  }
};

const Gamerules = () => {
  const rules = [
    {
      title: "Autoexit Loss",
      contentEn:
        "If you intentionally Autoexit, you will face a 100% Loss! If neither player has an open cut, the game can be canceled.",
      contentHi:
        "यदि आप जान भुजकर Autoexit करते हैं तो आपको 100% Loss कर दिया जायेगा! यदि दोनों प्लेयर में किसी की काटी खुली नहीं तो उसे हम कैंसिल कर सकते हैं!",
      color: "green",
    },
    {
      title: "Token Outside the House",
      contentEn:
        "If one token is outside and the player has others at home, a 30% Loss will be incurred. However, if the game has been played and two cuts are outside, the player leaving the game will face a 100% Loss!",
      contentHi:
        "यदि एक टोकन बाहर है और घर के पास है तो 30% Loss दिया जायेगा लेकिन यदि गेम खेला गया है और 2 काटी बाहर आयी हो तो गेम को लेफ्ट करने वाले को 100% Loss कर दिया जायेगा!",
      color: "red",
    },
    {
      title: "Autoexit Cancellation",
      contentEn:
        "If a game is canceled in Autoexit with one token outside, but you intentionally left the game, you will still incur a Loss. The final decision in such cases will be made by the Admin!",
      contentHi:
        "Autoexit में यदि 1 टोकन बाहर है तो गेम कैंसिल किया जा सकता है लेकिन यदि आपने गेम जान भुजकर में छोड़ा होगा तो आपको Loss ही दिया जाएगा इसमें अंतिम निर्णय Admin का होगा!",
      color: "blue",
    },
    {
      title: "Proof of Unfair Exit",
      contentEn:
        "If you suspect your opponent of intentionally leaving the game in Autoexit, and the Admin cancels it, video proof will be requested. Therefore, it's essential to record every game.",
      contentHi:
        "यदि आपको लगता है कि Opponent ने जानबूझकर गेम को Autoexit में छोड़ा है लेकिन Admin ने कैंसिल कर दिया है तो आपसे वीडियो प्रूफ मांगा जायेगा इसलिए हर गेम को रिकॉर्ड करना जरूरी है!",
      color: "purple",
    },
    {
      title: "Completion Time",
      contentEn:
        "Game results must be posted within 15 minutes of the game's completion, or the game will be updated based on the opponent's result.",
      contentHi:
        "Game समाप्त होने के 15 मिनट के अंदर रिज़ल्ट डालना आवश्यक है अन्यथा Opponent के रिज़ल्ट के आधार पर गेम अपडेट कर दिया जायेगा चाहे आप जीते या हारे और इसमें पूरी ज़िम्मेदारी आपकी होगी इसमें बाद में कोई बदलाव नहीं किया जा सकता है!",
      color: "teal",
    },
    {
      title: "Win Screenshot",
      contentEn:
        "If you win, provide the correct screenshot. If a wrong screenshot is uploaded, the game will be canceled. Contact the Admin via Live Chat if you forget to take a screenshot.",
      contentHi:
        "Win होने के बाद आप गलत स्क्रीनशॉट डालते हैं तो गेम को सीधा Cancel कर दिया जायेगा इसलिए यदि आप स्क्रीनशॉट लेना भूल गए हैं तो पहले Live Chat में एडमिन से संपर्क करें उसके बाद ही उनके बताए अनुसार रिज़ल्ट पोस्ट करें!",
      color: "brown",
    },
    {
      title: "Token Outside Cancels Game",
      contentEn:
        "If neither player's token (cut) is outside the house, the game can be canceled by the left player. Video proof is necessary for cancel proof.",
      contentHi:
        "'कैंसिल' रिज़ल्ट डालने के बाद गेम प्ले करके जीत जाते हैं तो उसमें हमारी कोई ज़िम्मेदारी नहीं होगी अतः गेम कैंसिल करने के बाद स्टार्ट न करें अन्यथा वह कैंसिल ही माना जाएगा!",
      color: "orange",
    },
    {
      title: "Result Once Posted",
      contentEn:
        "Once the result is posted, it cannot be changed. Think carefully before posting the result. Incorrect results may result in a penalty.",
      contentHi:
        "एक बार रिज़ल्ट डालने के बाद बदला नहीं जा सकता है इसलिए सोच समझकर रिज़ल्ट पोस्ट करें गलत रिज़ल्ट डालने पर पेनल्टी भी लगाई जाएगी चाहे आपने वह गलती से डाला हो या जानबूझकर!",
      color: "blue",
    },
    {
      title: "Cancellation of Fresh ID Games",
      contentEn:
        "Games with a fresh ID will be canceled based on the evidence of the use of the pass only, even if the pieces are open. Record of the game is necessary.",
      contentHi:
        "Fresh आईडी गेम के मामले में केवल पासा के उपयोग के प्रमाण पर रद्द कर दिया जाएगा गोटी के खुले होने के बावजूद नहीं? गेम का रिकॉर्ड होना जरूरी है अगर कोई अपनी आईडी का नाम नो फ्रेश कर रखा तो नो फ्रेश आईडी ही टेक करें जिनका इंटरेस्ट हो वही टेक करें टेबल को (इसका अंतिम रिजल्ट एडमिन देगा)।",
      color: "brown",
    },
  ];

  return (
    <div className="d-flex">
      <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
        <div className="m-3 py-5 pt-3 px-3">
          <h1 style={{ fontSize: '2.5em', color: '#333', marginBottom: '15px' }}>
            <strong>Ludo Rules</strong>
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ludo Rules
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-12">
              <h4 style={{ fontSize: '1.5em', color: '#555', marginBottom: '10px' }}>
                <strong>Game Rules:</strong>
              </h4>
              <ol className="rules-list">
                {rules.map((rule, index) => (
                  <li key={index}>
                    <span style={{ color: rule.color }}>{rule.contentEn}</span>
                    <span style={{ color: 'black' }}> {rule.contentHi}</span>
                  </li>
                ))}
              </ol>
              <h4>
                <strong>Commission Rates:</strong>
              </h4>
              <p style={{ color: 'orange' }}>
                Battles below ₹500 will incur a ₹25 commission on the battle amount.
              </p>
              <p style={{ color: 'purple' }}>
                Battles above ₹500 will incur a 5% commission on the battle amount.
              </p>
              <p style={{ color: 'brown' }}>
                For example, for a battle amount of ₹400, the commission would be: {calculateCommission(400)}₹
              </p>
              <p style={{ color: 'teal' }}>
                For example, for a battle amount of ₹600, the commission would be: {calculateCommission(600)}₹
              </p>
            </div>
          </div>
        </div>
      </div>
      <Rightcontainer />
    </div>
  );
};

export default Gamerules;