import React from 'react';

const UnderMaintenance = () => {
  const imageUrl = process.env.PUBLIC_URL + "/maintain.webp";

  return (
    <div className="container text-center my-5">
      <div className="row justify-content-center">
        <div className="col-md-8 col-sm-10">
          <img
            src={imageUrl}
            alt="Website Under Maintenance Notice"
            className="img-fluid mb-4"
            onError={(e) => e.target.style.display = 'none'} // Hide image if not found
          />
          <h2 className="mb-3">We're Under Maintenance</h2>
          <p className="mb-4">
            Our site is temporarily down for scheduled maintenance. We apologize for any inconvenience.
          </p>
          <div className="alert alert-warning p-3" role="alert">
            <strong>
              प्रिय खिलाड़ी,<br /><br />
              असुविधा के लिए खेद है! 😊 हमारी टीम LudoKheloYaar वेबसाइट पर कुछ जरूरी अपडेट्स और सुधार पर काम कर रही है ताकि अगली बार जब आप खेलें, तो आपका अनुभव और भी मजेदार और स्मूथ हो। 🙌<br /><br />
              आपसे अनुरोध है कि थोड़ा धैर्य बनाए रखें, आपका अमाउंट बिल्कुल सुरक्षित है और हम इस काम को जल्द से जल्द पूरा करने का प्रयास कर रहे हैं। 🙏<br /><br />
              थोड़ा सा समय दीजिए, हम जल्द ही वापस आ रहे हैं! धन्यवाद ! 🎲
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderMaintenance;
