import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";
import { decrypt } from "../utils/cryptoUtils";

const Support = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl
  }

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    try {
      const response = await fetch(baseUrl + "settings/data");
  
      // Check if the response is empty (status 204: No Content)
      if (response.status === 204 || response.headers.get("content-length") === "0") {
        throw new Error("No content in response");
      }
  
      // Check if the response is OK (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Try parsing the JSON
      const data = await response.json();
  
      // Now decrypt the data
      const decryptData = decrypt(data.data); // Assuming the encrypted data is inside `data.data`
      // console.log(decryptData.data);
      setWebsiteSettings(decryptData.data);
  
      // You can further handle the decrypted data here
      // setWebsiteSettings(decryptData);
    } catch (error) {
      console.log("Error fetching data:", error);
      document.title = (WebSitesettings) ? WebSitesettings.WebTitle : '';
    }
  };
  

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>



        <div className="cxy flex-column " style={{ paddingTop: "16%" }}>
          <img src={process.env.PUBLIC_URL + '/Images/contact_us.png'} width="280px" alt="" />
          <div className="games-section-title mt-4" style={{ fontSize: "1.2em", fontWeight: '700', color: '2c2c2c' }}>
            Contact us at below platforms.
          </div>

          <div className="row justify-content-center">
            <div className="col-4  d-flex justify-content-center w-80">
              <a className="cxy flex-column" href={(WebSitesettings.Telegram) ? "https://t.me/" + WebSitesettings.Telegram : ''}>
                <img width="50px" src={process.env.PUBLIC_URL + '/Images/tel.png'} alt="" />
                <span className="footer-text-bold">Telegram</span>
              </a>  &nbsp;&nbsp;&nbsp;&nbsp;

              <a className="cxy flex-column" href={(WebSitesettings.Instagram) ? 'https://www.instagram.com/' + WebSitesettings.Instagram + '/' : '/'}>
                <img width="50px" src={process.env.PUBLIC_URL + '/Images/instagram.png'} alt="" />
                <span className="footer-text-bold">Instagram</span>

              </a> &nbsp;&nbsp;&nbsp;&nbsp;

              <a className="cxy flex-column" href={(WebSitesettings.WhatsApp) ? "https://api.whatsapp.com/send?phone=" + WebSitesettings.WhatsApp + "&text=hello%20admin%20i%20need%20help" : ''}>
                <img width="50px" src={process.env.PUBLIC_URL + '/Images/whatsapp.png'} alt="" />
                <span className="footer-text-bold">Whatsapp</span>

              </a>
            </div>
          </div>


          <div className="col-12 my-2 text-center font-weight-bold">
            <a className="cxy flex-column" href={(WebSitesettings.CompanyEmail) ? 'mailto:' + WebSitesettings.CompanyEmail : ''}>
              <img width="50px" src={process.env.PUBLIC_URL + '/Images/mail.png'} alt="" />
              <span className="footer-text-bold">{(WebSitesettings.CompanyEmail) ? WebSitesettings.CompanyEmail : ''}</span>
            </a>
          </div>

          <div className="col-12 my-2 text-center font-weight-bold">
            <div className="cxy flex-column">
              {WebSitesettings.CompanyMobile ? (
                <a href={`tel:${WebSitesettings.CompanyMobile}`} className="footer-text-bold">
                  {WebSitesettings.CompanyMobile}
                </a>
              ) : (
                <button className="footer-text-bold" style={{ background: 'none', border: 'none', padding: 0, color: 'inherit', textDecoration: 'underline', cursor: 'pointer' }}>
                  UPDATE SOON
                </button>
              )}
              <span className="footer-text-bold">{WebSitesettings?.CompanyName || ''}</span>
              <span className="footer-text-bold">{WebSitesettings?.CompanyAddress || ''}</span>
            </div>
          </div>


          {/* <div className="col-12 my-2 text-center font-weight-bold">
            <a className="cxy flex-column" href="#">
              <span className="footer-text-bold"><a href={(WebSitesettings.CompanyMobile) ? 'tel:' + WebSitesettings.CompanyMobile : ''}>{(WebSitesettings.CompanyMobile) ? WebSitesettings.CompanyMobile : ''}</a></span>
              <span className="footer-text-bold">{(WebSitesettings) ? WebSitesettings.CompanyName : ''}</span>
              <span className="footer-text-bold">
                {(WebSitesettings) ? WebSitesettings.CompanyAddress : ''}</span>
            </a>
          </div> */}

        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Support;