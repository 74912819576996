import React, { useEffect, useState } from "react";
import { decrypt } from "../utils/cryptoUtils";

const Support = ({ user }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  let baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [WebSitesettings, setWebsiteSettings] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(baseUrl + "settings/data");

      if (response.status === 204 || response.headers.get("content-length") === "0") {
        throw new Error("No content in response");
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const decryptData = decrypt(data.data); // Assuming the encrypted data is inside `data.data`
      setWebsiteSettings(decryptData.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Ensure WebSitesettings is available
  if (!WebSitesettings) {
    return <div>Loading...</div>; // Show loading until WebSitesettings is fetched
  }

  // Assuming you have userPhone from a context or prop. If not, replace this with actual value.
  const userPhone = user?.Phone || "7023665614"; // Dynamically fetch user phone if available

  // Construct the WhatsApp link with the dynamic phone number
  const whatsappLink = WebSitesettings.WhatsApp
    ? `https://api.whatsapp.com/send?phone=${WebSitesettings.WhatsApp}&text=hello%20admin%20i%20need%20help%20my%20id%20no.%20*${user?.Phone}*`
    : '#';

  return (
    <div>
      <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
        <div className="cxy flex-column" style={{ paddingTop: "16%" }}>
          <img src={process.env.PUBLIC_URL + '/Images/contact_us.png'} width="280px" alt="Contact Us" />
          <div className="games-section-title mt-4" style={{ fontSize: "1.2em", fontWeight: '700', color: '#2c2c2c' }}>
            Contact us at below platforms.
          </div>

          <div className="row justify-content-center">
            <div className="col-4 d-flex justify-content-center w-80">
              <a className="cxy flex-column" href={WebSitesettings.Telegram ? `https://t.me/${WebSitesettings.Telegram}` : ''}>
                <img width="50px" src={process.env.PUBLIC_URL + '/Images/tel.png'} alt="Telegram" />
                <span className="footer-text-bold">Telegram</span>
              </a>&nbsp;&nbsp;&nbsp;&nbsp;

              <a className="cxy flex-column" href={WebSitesettings.Instagram ? `https://www.instagram.com/${WebSitesettings.Instagram}/` : '/'}>
                <img width="50px" src={process.env.PUBLIC_URL + '/Images/instagram.png'} alt="Instagram" />
                <span className="footer-text-bold">Instagram</span>
              </a>&nbsp;&nbsp;&nbsp;&nbsp;

              <a className="cxy flex-column" href={whatsappLink}>
                <img width="50px" src={process.env.PUBLIC_URL + '/Images/whatsapp.png'} alt="Whatsapp" />
                <span className="footer-text-bold">Whatsapp</span>
              </a>
            </div>
          </div>

          <div className="col-12 my-2 text-center font-weight-bold">
            <a className="cxy flex-column" href={WebSitesettings.CompanyEmail ? `mailto:${WebSitesettings.CompanyEmail}` : ''}>
              <img width="50px" src={process.env.PUBLIC_URL + '/Images/mail.png'} alt="Email" />
              <span className="footer-text-bold">{WebSitesettings.CompanyEmail || ''}</span>
            </a>
          </div>

          <div className="col-12 my-2 text-center font-weight-bold">
            <div className="cxy flex-column">
              {WebSitesettings.CompanyMobile ? (
                <a href={`tel:${WebSitesettings.CompanyMobile}`} className="footer-text-bold">
                  {WebSitesettings.CompanyMobile}
                </a>
              ) : (
                <button className="footer-text-bold" style={{ background: 'none', border: 'none', padding: 0, color: 'inherit', textDecoration: 'underline', cursor: 'pointer' }}>
                  UPDATE SOON
                </button>
              )}
              <span className="footer-text-bold">{WebSitesettings.CompanyName || ''}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
