import React, { useEffect, useState } from "react";
import css from "../css/gamehis.module.css";
import axios from "axios";
import Header from "../Components/Header";
import { decrypt } from "../utils/cryptoUtils";

const Referralhis = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const PUBLIC_URL = process.env.PUBLIC_URL;

  // Determine baseUrl based on NODE_ENV
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [user, setUser] = useState();
  const [cardData, setCardData] = useState([]);

  // Fetch user role and referral data
  const fetchUserData = async () => {
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    try {
      const res = await axios.get(baseUrl + `me`, { headers });
      const decryptedData = decrypt(res.data.data);
      setUser(decryptedData);
      fetchReferralHistory(decryptedData.referral_code);
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle error (e.g., show a message to the user)
    }
  };

  // Fetch referral history based on user's referral code
  const fetchReferralHistory = async (referralCode) => {
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    try {
      const res = await axios.get(baseUrl + `referral/code/winn/${referralCode}`, { headers });
      setCardData(res.data);
    } catch (error) {
      console.error("Error fetching referral history:", error);
      // Handle error (e.g., show a message to the user)
    }
  };

  // Format date for display
  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('default', { month: 'long', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
  };

  // Function to format closing balance with Math.floor
  const formatClosingBalance = (balance) => {
    return Math.floor(balance);
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!cardData || cardData.length === 0) {
    return (
      <div>
        <Header user={user} />
        <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
          <div className="text-center">
            <img
              src={`${PUBLIC_URL}/Images/notransactionhistory.png`}
              alt="no data"
              width={300}
              height={300}
              className="img-fluid"
              style={{ marginTop: "25%" }}
            />
            <div className="mt-2">
              <h3 className="font-weight-bold">No Refer History</h3>
              <p className="text-muted">You have not made any referrals yet.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header user={user} />
      <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
        {/* Pagination or other content */}
        <div className="pt-5 mb-3">
          {/* Add pagination or other content here if needed */}
        </div>

        {/* Render game cards */}
        {cardData.map((card) => (
          <div className={`w-100 py-3 d-flex align-items-center ${css.list_item}`} key={card._id}>
            <div className={`${css.center_xy} ${css.list_date} mx-2`}>
              <div>{dateFormat(card.createdAt).split(',')[0]}</div>
            </div>

            <div className={`${css.list_divider_y}`} />

            <div className={`mx-3 d-flex ${css.list_body}`}>
              <div className="d-flex align-items-center">
                <picture className="mr-2">
                  <img
                    height="32px"
                    width="32px"
                    src={`${PUBLIC_URL}/Images/LandingPage_img/ludo.jpeg`}
                    alt="Game Icon"
                    style={{ borderRadius: "5px" }}
                  />
                </picture>
              </div>

              <div className="d-flex flex-column font-8">
                <div>
                  <b style={{ color: 'black' }}>Referral earning</b>.
                </div>
                <div className={`${css.games_section_headline}`}>
                  <span style={{ color: 'blue' }}>Earned by: {card.earned_from?.Name || 'Unknown'}</span>
                </div>
              </div>
            </div>

            <div className="right-0 d-flex align-items-end pr-3 flex-column">
              <div className="d-flex float-right font-8">
                <div className="text-danger">{card.losestatus}</div>
                <div className="text-success">{card.winstatus}</div>
                <picture className="ml-1 mb-1">
                  <img
                    height="21px"
                    width="21px"
                    src={`${PUBLIC_URL}/Images/LandingPage_img/global-rupeeIcon.png`}
                    alt="Rupee Icon"
                  />
                </picture>
                <span style={{ color: 'green', paddingLeft: '5px' }}>{card.amount}</span>
              </div>
              <div className="games-section-headline" style={{ fontSize: "0.6em", whiteSpace: 'nowrap', color: 'red' }}>
                Closing Balance: <span style={{ color: 'red' }}>{formatClosingBalance(card.closing_balance)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Referralhis;
